import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
require('./bootstrap');
require('slick-carousel/slick/slick.min.js');

//Alerts
setTimeout(() => {
    $('.alert.notification').remove();
}, 4000);

//Slider Odds
if ($(".slider").length != 0) {
    $(".slider").show().slick({
        rows: 0,
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<div class="slick-prev arrow"><i class="fas fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next arrow"><i class="fas fa-chevron-right"></i></div>',
        responsive: [{
            breakpoint: 1700,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        }, {
            breakpoint: 750,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        }, ],
    });
}

//Slider Bonus Item
$(function() {
    if ($(".contentBody .sliderBonus").length != 0) {
        $(".contentBody .sliderBonus").show().slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<div class="slick-prev arrow"><i class="fas fa-chevron-left"></i></div>',
            nextArrow: '<div class="slick-next arrow"><i class="fas fa-chevron-right"></i></div>',
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 962,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }, ],
        });
    } else if ($(".sliderBonus").length != 0) {
        $(".sliderBonus").show().slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: '<div class="slick-prev arrow"><i class="fas fa-chevron-left"></i></div>',
            nextArrow: '<div class="slick-next arrow"><i class="fas fa-chevron-right"></i></div>',
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 962,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }, ],
        });
    }
});

//Fixed Header Scroll
var didScrollDesk;
var lastScrollTopDesk = 0;
var deltaDesk = 5;

$(window).scroll(function(event) {
    didScrollDesk = true;
});

setInterval(function() {
    if (didScrollDesk) {

        hasScrolled();
        didScrollDesk = false;
    }
}, 250);

function hasScrolled() {
    var st = $(document).scrollTop();

    if (Math.abs(lastScrollTopDesk - st) <= deltaDesk)
        return;

    if (st > lastScrollTopDesk && st >= 200) {
        $('body').addClass('scroll-up');
    } else {
        if (st + $(window).height() < $(document).height()) {
            $('body').removeClass('scroll-up');
        }
    }
    lastScrollTopDesk = st;
}

// Add Class Images With link
$('.body .contentBody a img').addClass("notZoom");
$('.contentBody figure img').on('click', function() {
    var link = $(this).parent().find('figcaption a');
    if (link) {
        $(link)[0].click();
        return false;
    }
})

//Zoom image Articles
Fancybox.bind(".body .contentBody img:not(.notZoom)");


$(".tclink-info").on("click", function() {
    var dataId = $(this).attr("data-toggle");
    $('#' + dataId).toggle();
});

$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})

$(".body h2").each(function(index) {
    var textH2 = $(this).text();
    $(this).attr("id", string_to_slug(textH2));
    $(this).addClass("elementAnchor");
});


/*
$( ".body iframe" ).each(function( index ) {
    var iframeHtml = $(this);
    console.log(iframeHtml);
    
    iframeHtml.parent().prepend('<div class="embed-responsive embed-responsive-16by9">').append('</div>');
    
});
*/

$(".detailsArticle .sidebarNav ul li a.anchor").each(function(index) {
    var textH2 = $(this).data('url');
    $(this).attr("href", "#" + string_to_slug(textH2));
    $(this).attr("id", "mob_" + string_to_slug(textH2));
});

var clickHash = false;
var timeOutHash = "";

var offsetAnchor = 140;


$('.detailsArticle .sidebarNav ul li a.anchor').click(function(event) {
    event.preventDefault();
    var sectionTo = $(this).attr('href');
    clickHash = true;
    if ($(window).width() <= 700) {
        offsetAnchor = 100;
    }

    $('html, body').animate({ scrollTop: $(sectionTo).offset().top - offsetAnchor }, 500);

    clearTimeout(timeOutHash);
    timeOutHash = setTimeout(function() {
        clickHash = false;
    }, 700);



});



var timeoutScrollAnchor = "";
var anchorActive = "";

$(document).scroll(function() {
    if ($(window).width() <= 700) {
        if ($('.authorInfos').length) {
            var anchorMenuMobile = $('.sideBarInfos');

            var topBodyScroll = $('.authorInfos').offset().top;

            var st = $(this).scrollTop();

            if (st >= topBodyScroll) {
                anchorMenuMobile.removeClass("hideAnchor");
            } else {
                anchorMenuMobile.addClass("hideAnchor");
            }
        }
    }

    $('.body .elementAnchor').each(function() {
        var top = window.pageYOffset;
        var distance = top - $(this).offset().top;

        var hash = $(this).attr('id');

        var offset = 0;

        if ($(window).width() <= 700) {
            offset = 80;
        }

        if (distance < 40 && distance > (-40 - offset)) {
            $('.detailsArticle .sidebarNav ul li a.anchor').removeClass("active");
            $('.detailsArticle .sidebarNav ul li a.anchor[href="#' + hash + '"]').addClass("active");

            var elem = $('#mob_' + hash);

            if (elem.offset()) {

                if ($(window).width() <= 700) {
                    if (anchorActive != hash) {
                        var btnAnchorID = $(this).attr('id');

                        var anchorLink = $('.anchor'),
                            current = anchorLink.filter('#mob_' + hash),
                            widthElmt = current.width(),
                            index = current.index('.anchor');

                        var container = $(".sidebarNav ul");

                        var withScroll = container.width();

                        var scrollContainer = container.offset().left;

                        var elemtAnchor = $('#mob_' + hash).offset().left;

                        var offsetAnchor = ((withScroll - 208) / 2);

                        var scrollToAnchor = ((index * 208) - offsetAnchor);

                        clearTimeout(timeoutScrollAnchor);

                        timeoutScrollAnchor = setTimeout(function() {
                            $(".sidebarNav").animate({ 'scrollLeft': scrollToAnchor }, 300);
                        }, 500);

                    }

                }

                $('.anchor').removeClass("active");
                elem.addClass("active");
            }

            anchorActive = hash;

        }

    });
});


function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

if (document.location.hash != "") {

    $('.detailsArticle .sidebarNav ul li a.anchor[href="' + document.location.hash + '"]').addClass("active");

}

//PROGRESSIVE SCROLL BAR
$(document).ready(function() {
    var docHeight = $(document).height(),
        windowHeight = $(window).height(),
        scrollPercent;

    $(window).scroll(function() {
        scrollPercent = $(window).scrollTop() / (docHeight - windowHeight) * 100;
        $('.scroll-progress').width(scrollPercent + '%');
    });
});

//GLOSSARY PAGE
$('.glossaries .filters .alphas ul li').on('click', function() {
    $('.glossaries .filters .alphas ul li').removeClass('active');
    $(this).addClass('active');
})
$('.glossaries .data .group li .topBody').on('click', function() {
    var checkHasClass = $(this).parent().hasClass('active');
    $('.glossaries .data .group li,  .glossaries .data').removeClass('active');

    if (!checkHasClass) {
        $(this).parent().addClass('active');
        $(this).parent().parents('.data').addClass('active');
    }
})
$(".glossaries #searchbar").on('keyup', function() {
    var value = $(this).val().toLowerCase();
    $(".glossaries .group li .name").each(function() {
        if ($(this).text().toLowerCase().search(value) > -1) {
            $(this).parents('li').show();
            //$(this).prev('.country').last().show();
        } else {
            $(this).parents('li').hide();
        }
    });
})


//Get more posts
let enabled = true;
let page = 1;
var totalPage = $('input[name=totalPage]').val();
var affiliateId = $('input[name=affiliateId]').val();
var categoryId = $('input[name=categoryId]').val();
$('.btnViewMore').on('click', function(el) {
    enabled = false;
    page++;
    var arrayData = {
        'page': page,
        'affiliateId': affiliateId,
        'categoryId': categoryId
    }

    $.ajax({
        url: "/posts/get",
        type: "GET",
        data: arrayData,
        async: false,
        //dataType: "html",
        success: function(data) {
            enabled = true;
            if (page == totalPage) {
                $('.btnViewMore').parents('.sectionBtnMore').hide();
            }
            $('.listCards .cards ul li:last').after(data);

        }
    });
})


// Menu Mobile .navbar-toggler

$('.navbar-toggler').on('click', function() {
    $(".navbar").toggleClass('open');
})